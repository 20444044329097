import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, Script } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Testimonials from "../components/Testimonials";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  content,
  contentComponent,
  testimonials,
}) => {
  const heroImage = getImage(image) || image;
  const PageContent = contentComponent || Content

  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.description}</h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="red-btn"
                        title="NON aux centrales éoliennes dans les Monts d'Arrée"
                        href="https://www.change.org/p/non-aux-centrales-%C3%A9oliennes-dans-les-monts-d-arr%C3%A9e"
                      >
                        Signer la pétition
                      </a>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <PageContent content={content} />
                      <br />
                      <div className="has-text-centered">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn"
                          title="Adhérer à l'association Arrée Résistance Rurale"
                          href="https://www.helloasso.com/associations/arree-resistance-rurale/adhesions/adhesion-annuel"
                        >
                          Adhérer à l'association Arrée Résistance Rurale
                        </a>
                      </div>

                      <h3 className="has-text-weight-semibold is-size-2">
                        Écoutons les riverains des centrales éoliennes
                      </h3>
                      <Testimonials testimonials={testimonials} />
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/eoliennes/">
                        En savoir plus sur les éoliennes industrielles
                      </Link>
                    </div>
                  </div>
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Dernières nouvelles
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog/">
                        Toutes les nouvelles
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Script id="netlify-identity-redirect">{
        `if (window.netlifyIdentity) {
          window.netlifyIdentity.on("init", user => {
            if (!user) {
              window.netlifyIdentity.on("login", () => {
                document.location.href = "/admin/";
              });
            }
          });
        }`
      }</Script>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  testimonials: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <IndexPageTemplate
        image={page.frontmatter.image}
        title={page.frontmatter.title}
        heading={page.frontmatter.heading}
        subheading={page.frontmatter.subheading}
        mainpitch={page.frontmatter.mainpitch}
        content={page.html}
        contentComponent={HTMLContent}
        testimonials={page.frontmatter.testimonials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        testimonials {
          title
          video
          author
          quote
        }
      }
    }
  }
`
